<template>
  <div class="my-8 callHistory">
    <v-row>
      <v-col>
        <h2 class="main-color">{{ $t("All Calls") }}</h2>
      </v-col>
      <v-col class="text-center">
        <!-- <label class="search" for="search">
                    <input id="inpt_search" class="input_search" v-model="search" @keyup.enter="getAllHistory"
                        @focus="inputFocus" @blur="inputBlur" type="text" />
                    <v-icon @click="getAllHistory" :title="$t('Search')" class="search-icon">search</v-icon>
                </label> -->
      </v-col>
      <v-col :class="currentAppLocale == 'en' ? 'text-right' : 'text-left'">
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              :title="$t('Search')"
              large
              color="#7297ff"
              @click="initializeDatePicker"
            >
              mdi-filter</v-icon
            >
          </template>

          <v-card class="py-4">
            <v-list>
              <v-list-item class="select">
                <v-select
                  v-model="filterData.grades"
                  multiple
                  :items="grades"
                  item-text="name"
                  item-value="id"
                  :placeholder="$t('Grades')"
                  solo
                ></v-select>
              </v-list-item>
            </v-list>

            <v-list>
              <v-list-item class="select">
                <v-select
                  v-model="filterData.classes"
                  multiple
                  :items="classes"
                  item-text="name"
                  item-value="id"
                  :placeholder="$t('Classes')"
                  solo
                ></v-select>
              </v-list-item>
            </v-list>

            <!-- <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn color="primary" text @click="filterMenu = false">{{
                                    $t("Close")
                            }}</v-btn>
                        </v-card-actions> -->
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-data-table
      :options.sync="options"
      :loading="loading"
      loading-text="Loading... Please wait"
      :headers="headers"
      :items="items"
      item-key="id"
      :show-select="false"
      class="elevation-1 level1 templates text-center"
      text="left"
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          class="main-color"
          title="Checkout"
          @click="checkout(item.code)"
          large
          >logout</v-icon
        >
      </template>
    </v-data-table>
    <div class="text-center my-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :total-visible="totalVisible"
        circle
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "allcalls",
  data() {
    return {
      search: "",
      filterMenu: false,
      filterData: {
        classes: [],
        grades: "",
      },
      classes: [],
      grades: [],
      loading: false,
      options: {
        itemsPerPage: 15,
      },

      circle: true,
      page: 1,
      length: 1,
      totalVisible: 5,
      headers: [
        {
          text: this.$i18n.t("Student Name"),
          align: "start",
          sortable: false,
          value: "student_name",
        },
        {
          text: this.$i18n.t("Grade and Class"),
          value: "class_name",
          sortable: false,
        },

        {
          text: this.$i18n.t("Call Time"),
          value: "call_time",
          sortable: false,
        },
        {
          text: this.$i18n.t("Called By"),
          value: "called_by",
          sortable: false,
        },
        {
          text: this.$i18n.t("Retries"),
          value: "retries",
          sortable: false,
        },
        {
          text: this.$i18n.t("Checkout"),
          value: "actions",
          sortable: false,
        },
      ],
      items: [],
    };
  },
  watch: {
    page: function () {
      this.getData();
    },
    options: {
      handler() {},
      deep: true,
    },
    filterData: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    getClasses() {
      this.$http
        .get(this.getApiUrl + "/discussionboard/getClasses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data;
        });
    },

    getGrades() {
      this.$http
        .get(this.getApiUrl + "/nedaa/getGrades", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.grades = response.data.data;
        });
    },

    getData() {
      this.$http
        .get(
          this.getApiUrl +
            "/nedaa/getAllCalls?page=" +
            this.page +
            "&class=" +
            this.filterData.classes +
            "&grades=" +
            this.filterData.grades,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.items = response.data.data.data;
          this.page = response.data.data.current_page;
          this.options.itemsPerPage = response.data.data.per_page;
          this.length = response.data.data.last_page;
        });
    },
    checkout(code) {
      if (
        confirm(this.$i18n.t("Are you sure you want to checkout this student?"))
      ) {
        this.$http
          .get(this.getApiUrl + "/nedaa/checkOutStudent/" + code, {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          })
          .then((response) => {
            code;
            if (response.data.status.error == false) {
              this.getData(this.page);
            }
          });
      }
    },
  },
  mounted() {
    this.getData();
    this.getClasses();
    this.getGrades();
    let _this = this;
    if (process.env.VUE_APP_SOCKET_URL != undefined) {
      this.$socket.channel("StudentCallList").listen("StudentCallList", (e) => {
        _this.getData();
        console.log(e);
      });
    }
  },
};
</script>

<style></style>
